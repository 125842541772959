<template>
  <div class="myOrderDetails">
    <van-notice-bar
        left-icon="volume-o"
        text="温馨提示：请确保本次租赁服务是在本人自愿条件下下单，下单人须承担缴纳租金责任以及逾期后面临的法律诉讼，利用平台进行贷款交易是违法行为，若发生以上行为，请联系平台提供线索或报警处理"
        color="#FB3333" background="#FFF7F7"
    />
   <div style="padding: 0rem 0.32rem">
     <div class="myOrderAddress" v-if="$store.state.addressShow ==='线下配送'" @click="getSite">
       <div>
         <h3 v-show="currentSite.siteName==''">请添加收货地址</h3>
         <p v-show="currentSite.siteName==''">地址请精确到道路、小区、楼栋号等</p>
         <h3>{{currentSite.siteName}} {{currentSite.sitePhone}}</h3>
         <p>{{currentSite.siteAddress}}</p>
       </div>
       <div class="iconfont icon-xiayiyeqianjinchakangengduo"></div>
       <img src='../../images/decorate.png' class="decorate">
     </div>
     <div class="myOrderCode">
       <div>
         <img :src="this.$store.state.particulars.img"/>
         <div>
           <h3>{{this.$store.state.particulars.name}}</h3>
           <p>
             租期：{{this.$store.state.particulars.newTime}}-{{this.$store.state.particulars.endDate}}
           </p>
           <p>
             数量：{{this.$store.state.particulars.valueNum}}
           </p>
         </div>
       </div>
       <div>
         提示：租期包含免费赠送7天,所有测量数据本公司不予保存!
       </div>
     </div>
     <div class="aboutOrder">
       <van-form>
         <div>
           <van-field
               readonly
               clickable
               name="picker"
               :value="$store.state.addressShow"
               label="收货方式"
               placeholder="请选择收货方式"
               @click="showPicker = true"
               input-align="right"
               right-icon="arrow"
               required
           />
           <van-popup v-model="showPicker" position="bottom">
             <van-picker
                 show-toolbar
                 :columns="columns"
                 @confirm="onConfirm"
                 @cancel="showPicker = false"
             />
           </van-popup>
         </div>
         <div>
           <van-field
               readonly
               clickable
               name="picker"
               :value="serviceOutletsValue"
               label="服务门店"
               placeholder="请选择服务门店"
               @click="serviceOutletsShow = true"
               input-align="right"
               right-icon="arrow"
               required
               v-show="$store.state.addressShow ==='到店自取'"
           />
           <van-action-sheet v-model="serviceOutletsShow" title="门店地址（9）">
             <div class="content">
               <van-address-list
                   v-model="chosenAddressId"
                   :list="storeList"
                   add-button-text="确认"
                   @add="onAdd()"
                   @click-item="my"
                   @select="selectAddress"
               />
             </div>
           </van-action-sheet>
         </div>
       </van-form>
       <div>
         <van-field
             v-model="remarkValue"
             label="订单备注"
             placeholder="建议备注前先于客服沟通确认"
             input-align="right"
             style="border-bottom-left-radius: 0.28rem;border-bottom-right-radius: 0.28rem"
         />
       </div>
     </div>
     <div class="aboutPrice">
       <div class="rentalFee">
         <div>租赁费</div>
         <div>{{this.$store.state.particulars.unitPrice}}×{{this.$store.state.particulars.valueNum}}台×{{this.$store.state.particulars.value}}天={{this.$store.state.particulars.total}}元</div>
       </div>
       <div class="equipmentDeposit">
         <div>
           <div>设备保证金</div>
           <van-icon name="warning-o" class="equipmentDepositIcon" @click="equipmentDepositIcon1"/>
         </div>
         <div>
           {{this.$store.state.particulars.guarantee.unitPrice}}×1台={{this.$store.state.particulars.guarantee.unitPrice}}元
         </div>
       </div>
       <div class="equipmentDeposit">
         <div>
           <div>设备服务费</div>
           <van-icon name="warning-o" class="equipmentDepositIcon" @click="equipmentDepositIcon2"/>
         </div>
         <div>
           {{this.$store.state.particulars.serviceCharge.unitPrice}}×1台={{ this.$store.state.particulars.serviceCharge.unitPrice}}元
         </div>
       </div>
       <div class="theAmountActuallyPaid ">
         <div>实付总租金</div>
         <div>
           <span>￥</span>
           {{(this.$store.state.particulars.total+this.$store.state.particulars.guarantee.unitPrice+this.$store.state.particulars.serviceCharge.unitPrice).toFixed(2)}}
         </div>
       </div>
     </div>
     <div class="payType">
       <van-cell-group>
         <van-field  v-model="modeOfPaymentColumns" label="支付方式"   input-align="right" readonly />
       </van-cell-group>
     </div>
     <div class="leasingAgreement ">
       <van-checkbox v-model="checked" checked-color="#FB884F" icon-size="0.42rem" label-disabled>我已阅读并同意<span @click="leasingAgreementTxt">“租赁服务协议”</span></van-checkbox>
     </div>
     <div class="submitOrder">
       <div>
         总租金：<span>￥</span><i>{{(this.$store.state.particulars.total+this.$store.state.particulars.guarantee.unitPrice+this.$store.state.particulars.serviceCharge.unitPrice).toFixed(2)}}</i>
       </div>
       <div @click="subBtn">提交订单</div>
     </div>
   </div>
  </div>
</template>

<script>
import {Dialog, Notify, Toast} from 'vant'
import leaseApi from "../../../utils/leaseApi";
export default {
  name: "myOrderDetails",
  computed : {
    orderData() {
      return this.$store.state.orderData
    },
      orderSite(){
      return this.$store.state.orderSite
    }
  },
  data() {
    return {
      receiveIndex : 0,
      serviceOutletsValue: '',//服务门店
      remarkValue: '',//备注
      columns: ['线下配送','到店自取' ],
      modeOfPaymentColumns:'在线支付',
      showPicker: false,
      serviceOutletsShow: false,
      modeOfPaymentValue:'',
      modeOfPaymentShow:false,
      checked: true,
      // 选择具体门店
      chosenAddressId: 1,
      site:"",//收货地址
      particulars:"",//订单详情
      currentSite : {
        siteAddress:"",
        siteName:"",
        sitePhone:"",
        id : null
      },
      loading: false,
      // 门店列表
      storeList: [],
    }
  },
  methods: {
    onConfirm(value,index) {
      this.receiveIndex = index;
      this.showPicker = false;
      this.$store.commit('setAddressShow',value)
      if(value=='到店自取'){
        this.chosenAddressId=1
        this.serviceOutletsValue= '成都测绘玩家'
      }
    },
    modeOfPaymentOnConfirm(value){
      this.modeOfPaymentValue = value;
      this.modeOfPaymentShow = false;
    },
    onAdd(e) {
      this.serviceOutletsShow = false
    },
    my(item, index) {
      this.serviceOutletsValue = item.name
    },
    equipmentDepositIcon1(){
      Dialog.alert({
        title: '提示',
        message: '用于支付主配件丢失损坏的小额赔偿， 具体数额在设备退还后进行结算或全 额退还（无小额赔偿情况）。',
        confirmButtonText:'我知道了',
        confirmButtonColor:'#3366FF'
      }).then(() => {
        // on close
      });
    },
    equipmentDepositIcon2(){
      Dialog.alert({
        title: '提示',
        message: '租赁设备日常保养产生的费用。',
        confirmButtonText:'我知道了',
        confirmButtonColor:'#3366FF'
      }).then(() => {
        // on close
      });
    },
    leasingAgreementTxt(){
      this.$router.push({name:'leasingAgreement'})
    },
    getSite(){
      // console.log(this.currentSite)
      if (this.currentSite.siteId != null){
        this.$router.push({name: "deliveryAddress",query:{type:"1"}})
      }else {
        this.$router.push({name: "deliveryAddress"})
      }
    },
    //获取默认地址
    async getMorenSite(){
      if (this.orderSite.id === null){
        const {data} = await leaseApi.getSiteList()
        this.list = data.map(d => {
          let map = d
          map.name = d.recipients
          map.tel = d.phone
          map.address = d.province + d.city + d.county + d.detailedAddress
          map.isDefault = d.defaultStatus === 1
          return map;
        })
        this.currentSite.siteName=data[0].name
        this.currentSite.siteAddress=data[0].address
        this.currentSite.sitePhone=data[0].phone
        this.currentSite.siteId = data[0].id
      }else {
        this.currentSite = this.orderSite
      }

    },
    selectAddress(e){
      this.chosenAddressId=e.id
      console.log(this.chosenAddressId);
    },
    // 提交订单
    async subBtn(){
      // console.log(this.currentSite)
      // console.log(this.$store.state.addressShow)
      if(this.$store.state.addressShow === ''){
        return Toast('请选择收货方式');
      }else if(this.$store.state.addressShow === '到店自取'){
        if (this.chosenAddressId == null){
          return Toast('请选择服务门店');
        }
      }else if (this.$store.state.addressShow === '线下配送'){
        if (this.currentSite.siteId == null){
          return Toast('请选择收货地址');
        }
      }
      // 自定义加载图标
      await Toast.loading({
        message: '下单中..',
        forbidClick: true,
        duration : 0,
        loadingType: 'spinner',
      });

      // 接口
      let orderData = {
        commodityId: this.orderData.commodityId,
        rentDay: this.orderData.rentDay,
        receiveType : this.receiveIndex,
        customerRemark : this.remarkValue,
        serviceStore : this.chosenAddressId,
        siteId : this.currentSite.siteId
      }
      const { data,code,msg } = await leaseApi.createOrder(orderData)
      if(code === 200){
        Toast.success("下单成功")
          this.weChatPay(data)
      }else {
        Toast.fail(msg);
        if (msg === '请先通过实名认证'){
          await this.$router.push({name : 'freeAndCertification'})
        }
      }
    },

    async getStoreSite(){
      const { data } = await leaseApi.getStoreSite();
      // console.log(data)
      data.forEach(d => {
        let a = {
          id: d.id,
          name: d.storeName,
          tel: d.phone,
          address: d.province + d.city + d.county + d.detailedAddress
        }
        this.storeList.push(a)
      })
    },

    weChatPay(data){
      WeixinJSBridge.invoke('getBrandWCPayRequest',data,
          function(res) {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              // 支付成功
              Toast.success('支付成功');
              setTimeout(function () {
                window.location.href = 'http://maplays.net/#/order'
              },2000)
            }else if(res.err_msg == "get_brand_wcpay_request:cancel"){
              // 用户取消
              Toast.fail("取消支付");
            }else if(res.err_msg == "get_brand_wcpay_request:fail"){
              // 支付失败
              alert("支付失败");
            }else {
              //其他原因
              alert("有其他原因");
            }
          });
    }
  },

  created(){
    this.getMorenSite()
    this.getStoreSite()
  },

}
</script>

<style lang="less" scoped>
/deep/ .van-notice-bar {
  height: 0.83rem;
  line-height: 0.83rem;
}

.myOrderDetails {
  height: 100%;
  background: #F6F6F6;
  padding-bottom: 5.4rem;
  .myOrderAddress {
    display: flex;
    justify-content: space-between;
    padding: 0.43rem 0.67rem 0.44rem 0.67rem;
    background: #FFFFFF;
    border-radius: 0.21rem;
    margin-top: 0.33rem;
    position: relative;
    .decorate{
      position: absolute;
      width: 9.36rem;
      height: 0.13rem;
      bottom: 0rem;
      left: 0rem;
    }
    div:nth-child(1) {
      h3 {
        height: 0.4rem;
        font-size: 0.42rem;
        font-family: xiaocheng;
        font-weight: 500;
        color: #333333;
        margin-bottom: 0.33rem;
      }

      p {
        height: 0.33rem;
        font-size: 0.36rem;
        font-family: xiaocheng;
        font-weight: 400;
        color: #999999;
      }
    }

    div:nth-child(2) {
      align-self: center;
      font-size: 0.4rem;
      color: #999999;
    }
  }

  .myOrderCode {
    background: #FFFFFF;
    margin-top: 0.28rem;
    border-radius: 0.21rem;

    > div:nth-child(1) {
      display: flex;
      margin-bottom: 0.33rem;
      padding: 0.44rem 0rem 0rem 0.44rem;

      img {
        width: 2.61rem;
        height: 2.61rem;
        margin-right: 0.44rem;
        //border: 0.01px solid #EEEEEE;
      }

      h3 {
        font-size: 0.44rem;
        font-family: xiaocheng;
        font-weight: bold;
        color: #333333;
        margin-bottom: 0.44rem;
      }

      p {
        font-size: 0.42rem;
        font-family: xiaocheng;
        font-weight: 400;
        color: #666666;
        margin-bottom: 0.32rem;
      }
    }

    > div:nth-child(2) {
      height: 0.94rem;
      background: linear-gradient(86deg, #FFEEE5 0%, #FFF7F3 52%, #FFF7F3 100%);
      padding-left: 0.44rem;
      font-size: 0.31rem;
      font-family: xiaocheng;
      font-weight: 400;
      color: #FB884F;
      line-height: 0.94rem;
      border-bottom-left-radius: 0.21rem;
      border-bottom-right-radius: 0.21rem;

    }
  }

  .aboutOrder {
    margin-top: 0.28rem;
    border-radius: 0.21rem;
    overflow: hidden;
  }

  .aboutPrice {
    margin-top: 0.28rem;
    border-radius: 0.21rem;
    background: #FFFFFF;
    padding: 0.43rem;

    .rentalFee {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.65rem;

      div:nth-child(1) {
        font-size: 0.42rem;
        font-family: xiaocheng;
        font-weight: 400;
        color: #333333;
      }

      div:nth-child(2) {
        font-size: 0.39rem;
        font-family: xiaocheng;
        font-weight: 400;
        color: #333333;
      }
    }

    .equipmentDeposit {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.65rem;

      > div:nth-child(1) {
        font-size: 0.42rem;
        font-family: xiaocheng;
        font-weight: 400;
        color: #333333;
        display: flex;

        .equipmentDepositIcon {
          align-self: center;
          color: #FB884F;
          margin-left: 0.13rem;
        }
      }

      > div:nth-child(2) {
        font-size: 0.39rem;
        font-family: xiaocheng;
        font-weight: 400;
        color: #333333;
      }

    }
  }
  .payType{
    border-radius: 0.21rem;
    margin-top: 0.28rem;
    overflow: hidden;
  }
  .theAmountActuallyPaid {
    display: flex;
    justify-content: space-between;

    div:nth-child(1) {
      font-size: 0.42rem;
      font-family: xiaocheng;
      font-weight: 500;
      color: #333333;
    }

    div:nth-child(2) {
      font-size: 0.44rem;
      font-family: xiaocheng;
      font-weight: 400;
      color: #333333;

      span {
        font-size: 0.33rem;

      }
    }
  }


  .leasingAgreement{
    margin-top: 0.28rem;
    background: #FFFFFF;
    border-radius: 0.21rem;
    font-size: 0.36rem;
    font-family: xiaocheng;
    font-weight: 400;
    color: #333333;
    padding: 0.44rem;
    span{
      color: #FF9966;
    }
  }
  .submitOrder{
    margin-top: 0.28rem;
    height: 1.64rem;
    background: #FFFFFF;
    display: flex;
    justify-content: space-between;
    padding: 0rem 0.44rem;
    position: fixed;
    bottom: 1.6rem;
    width: 9.1rem;
    div:nth-child(1){
      font-size: 0.44rem;
      font-family: xiaocheng;
      font-weight: 500;
      color: #000000;
      align-self: center;
      span{
        font-size: 0.33rem;
        color: #FB884F;
      }
      i{
        font-size: 0.64rem;
        font-family:xiaocheng;
        font-weight: 400;
        color: #FB884F;
        font-style: normal;
      }
    }
    div:nth-child(2){
      align-self: center;
      width: 4.56rem;
      height: 1.11rem;
      background: #FB884F;
      box-shadow: 0rem 0rem 0rem 0rem rgba(251, 140, 82, 0.13);
      border-radius: 1rem;
      font-size: 0.44rem;
      font-family: xiaocheng;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 1.11rem;
      text-align: center;
    }
  }
}

/deep/ .van-cell--required::before {
  color: #FB884F;
}

/deep/ .van-address-item__edit {
  display: none;
}

/deep/ .van-address-item .van-radio__icon--checked .van-icon {
  background-color: #FB884F;
  border-color: #FB884F;
}

/deep/ .van-button--danger {
  background-color: #FB884F;
  border: 0.02667rem solid #FB884F;
}

/deep/ .van-address-item__value {
  padding-right: 0rem;
}

/deep/ .van-address-item {
  border-bottom: 1px solid #EFEFEF;
}

/deep/ .van-address-item {
  border-radius: 0rem;
}
.van-radio-group {
  margin-bottom: 5.89rem;
}
/deep/.van-cell::after{
  border:0rem
}
/deep/[class*=van-hairline]::after{
  border:0rem
}
/deep/.van-field__label{
  color: #333;
}
/deep/.van-action-sheet__header{
  text-align: left;
  text-indent: 0.36rem;
}
</style>
